import React, { Component } from "react"
import Layout from "../components/layout"
import Row from "../components/row"
import particlesConf from "../config/particles.conf"
import { graphql, Link } from "gatsby"
// import headerImage from "../images/backgrounds/home-bg.jpg";
import banner from "../images/backgrounds/banner.jpg"
import bannerF1 from "../images/backgrounds/banner-f1.jpg"
import Particles from "react-particles-js"
import text from "../data/nl";
import NewsBlock from "../components/newsBlock"
import SEO from "../components/seo"
import arrow from "../images/news/chevron-right.svg"

import { animateScroll as scroll } from 'react-scroll'

class IndexPage extends Component {

  constructor(props) {
    super(props)
    this.state = {arrowOpacity: {opacity: 100}};
  }

  getScrollPosition(scroll) {
    this.setState(() => ({ arrowOpacity: { opacity: (400 - scroll) / 400 } }));
  }

  render() {
    const data = this.props.data;
    return (
      <Layout bigfoot={true} setScrollPosition={this.getScrollPosition.bind(this)}>
        <SEO title={text.home.meta.title} description={text.home.meta.description}/>
        <Row size="big full-height" video={text.home.jumbotron.video} classes="h-980 bg-cover" id="HomeJumbotron">
          <div className="relative container small pt-420 full-height">
            <div className="jumbotron column col-12">
              <h1 className="uppercase t-90 bold lh-115 relative">
                {text.home.jumbotron.title}
                <span className="block t-58 lh-100">{text.home.jumbotron.subtitle}</span>
              </h1>
              <Link to={text.home.jumbotron.callToActionLink}
                    className="jumbotron-button mt-40">{text.home.jumbotron.callToAction}</Link>
              <Link to="/timextender"
                    className="hide-899 jumbotron-button mt-40 ml-50">Download TimeXtender whitepaper</Link>
            </div>


            <svg onClick={() => scroll.scrollMore(800)} style={this.state.arrowOpacity} className="fill-white w-60 center-horizontally scroll-indicator hover-w-70 pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M443.5 162.6l-7.1-7.1c-4.7-4.7-12.3-4.7-17 0L224 351 28.5 155.5c-4.7-4.7-12.3-4.7-17 0l-7.1 7.1c-4.7 4.7-4.7 12.3 0 17l211 211.1c4.7 4.7 12.3 4.7 17 0l211-211.1c4.8-4.7 4.8-12.3.1-17z"/></svg>

          </div>
        </Row>

        {/* slogan & explanation */}
        <Row size="big">
          <div className="hbox">
            <div className="column col-6 deep-blue" id="HomeSlogan">
              <div className="pl-95 pr-95 pt-150 pb-150 t-50 bold uppercase align-center p-40 lh-140">
                {text.home.slogan}
              </div>
            </div>
            <div className="column col-6 blue" id="HomeExplanation">
              <div className="pl-55 pr-55 pt-115 pb-115">
                <h2 className="t-20 bold center mw-530 align-center mb-40 lh-140">{text.home.explanation.title}</h2>
                <p className="align-justify">{text.home.explanation.text}</p>
              </div>
            </div>
          </div>
        </Row>

        {/* abilities */}
        <Row image={banner} classes=" bg-repeat-y bg-cover " id="HomeAbilities">
          <div className="hbox space-between pt-80 pb-80 column col-12">
            {text.home.abilities.map((ability) =>
              <div className="relative p-50 col-6" key={ability.ref}>
                <div className="f-container">
                  <img src={ability.image} className="mw-120 white-text f-center" alt=""/>
                  <div className="ml-60 mr-30">
                    <h3 className="t-17 bold uppercase mb-20">{ability.title}</h3>
                    <p className="align-justify lh-150 hyphen-manual" dangerouslySetInnerHTML={{__html: ability.text }} />
                  </div>
                  <Link to={ability.ref} className="overlay-link"/>
                </div>
              </div>
            )}
          </div>
        </Row>

        {/* partners */}
        <Row classes="grey" id="HomePartners">
          <div className="pt-80 pb-80 column col-12">
            <h3 className="t-50 uppercase bold mb-40">{text.home.partners.title}</h3>
            <ul className="hbox cross-center list-style-none space-between mb-40 partner-list">
              {text.home.partners.partners.map((partner, index) =>
                <li className={"mt-40 h-100 partner-list-item-" + index} key={partner.alt}>
                  <Link to={partner.ref} className="block full-height relative">
                    <img className="max-width-100 max-height-100 center-vertically" src={partner.image}
                         alt={partner.alt}
                         style={{ height: partner.imageHeight != null ? partner.imageHeight : "auto" }}/>
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </Row>

        {/* Particles */}
        <Row image={bannerF1} classes="h-869" id="HomeParticles">
          <div className="column col-12">
            <div className="mt-100 mb-140 relative mw-637 center">
              <div className="particle-circle-container">
                <Particles params={particlesConf}/>
              </div>
              {/* todo: a loop would suffice here*/}
              <Link to={text.home.businessUnits[0].ref}
                    className="particle-circle-item particle-circle-item-1 block">{text.home.businessUnits[0].title}</Link>
              <Link to={text.home.businessUnits[1].ref}
                    className="particle-circle-item particle-circle-item-2 block">{text.home.businessUnits[1].title}</Link>
              <Link to={text.home.businessUnits[2].ref}
                    className="particle-circle-item particle-circle-item-3 block">{text.home.businessUnits[2].title}</Link>
              <Link to={text.home.businessUnits[3].ref}
                    className="particle-circle-item particle-circle-item-4 block">{text.home.businessUnits[3].title}</Link>
            </div>
          </div>
        </Row>

        {/* News */}
        <Row classes="grey pt-80 pb-80" id="HomeNews">
          <div>
            <div className="column col-12">
              <h3 className="t-50 uppercase bold mb-40">{text.home.news.title}</h3>
            </div>
            <ul className="hbox space-between list-style-none">
              {data.allNews.edges.map((article) =>
                <li className="column mt-40 col-30p" key={article.node.title}>
                  <NewsBlock article={article.node}/>
                </li>
              )}
              <li className="column mt-40 col-10p block" style={{ minHeight: "200px" }}>
                <Link to="/nieuws" className="full-height relative block transition">
                  <img className="center-vertically-and-horizontally block w-60 hover-w-70 transition" src={arrow} alt="arrow"/>
                </Link>
              </li>
            </ul>
          </div>
        </Row>
      </Layout>
    )
  }
}
export default IndexPage

export const lastNewsQuery = graphql`
  query lastNewsQuery {
    allNews( limit: 3 ) {
      totalCount
      edges {
        node {
          title
          thumbnail
          background
          content
          excerpt
          path
          date(formatString: "dd-MM-yyyy")
          tags
        }
      }
    }
  }
`
