import { Link } from "gatsby"
import React from "react"
import text from "../data/nl"



// alle nieuws online zetten.
// data date correct maken

const NewsBlock = ({article}) => (

      <div className="relative">
        <div className="h-200 mb-28 full-width center-bg" style={{backgroundImage: 'url('+article.thumbnail+')'}}>
        </div>
        <h4 className="bold t-16 mb-28">
          {article.title}
        </h4>
        <p className="align-justify mb-28">{article.excerpt}</p>
          <Link to={article.path} className="bold"> {text.news.readMore} </Link>
          <Link to={article.path} className="overlay-link"/>
      </div>

)

export default NewsBlock
